import React from 'react'

export default function Footer() {
      return (
          <div id="footer">
            &copy; {new Date().getFullYear()} | KindCard
          </div> 
      );
  }

  